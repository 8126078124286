import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { VueCookieNext } from 'vue-cookie-next';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css?v0.2';

import { createPinia } from 'pinia';
import { useAuthStore } from "./store";
import './registerServiceWorker';
import {p} from "@/config_vars/index";
p.then(()=>{
  const pina = createPinia();

  const app = createApp(App)
    .use(IonicVue)
    .use(VueCookieNext)
    .use(pina);
    
  //get the store
  const store = useAuthStore();


  store.initializeAuthListener().then(() => {
    app.use(router).mount("#app");
  });

  //Refresh after some time of inactivity
  let time = new Date().getTime();
  const setActivityTime = () => {
    time = new Date().getTime();
  }
  document.body.addEventListener("mousemove", setActivityTime);
  document.body.addEventListener("mousedown", setActivityTime);
  document.body.addEventListener("keypress", setActivityTime);
  document.body.addEventListener("keydown", setActivityTime);
  document.body.addEventListener("scroll", setActivityTime);
  document.body.addEventListener("touchstart", setActivityTime);

  const refresh = () => {
    if (new Date().getTime() - time >= 5*60*60000) {
      window.location.reload();
    } else {
      setTimeout(refresh, 60*60000);
    }
  }
  setTimeout(refresh, 60*60000);
})