/* eslint-disable no-console */

import { register } from "register-service-worker";

//if (process.env.NODE_ENV === 'production') {
register("/service-worker.js", {
  /*async ready(swRegistration) {
    const permission = await window.Notification.requestPermission();
    if (permission !== "granted") {
      console.log("Permission not granted for Notification");
      //throw new Error("Permission not granted for Notification");
    }
    swRegistration.showNotification('This is Title',{body: 'This is message'});
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
  },*/
  /*registered() {
    console.log("Service worker has been registered.");
  },*/
  /*cached() {
    console.log("Content has been cached for offline use.");
  },*/
  /*updatefound() {
    console.log("New content is downloading.");
  },*/
  updated(registration) {
    document.dispatchEvent(
      new CustomEvent("swUpdated", { detail: registration })
    );
  },
  /*offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
  },*/
});
//}
