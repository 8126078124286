import { defineStore } from "pinia";
import {
  fbAuthStateListener,
  fbCreateAccount,
  fbSignIn,
  fbSignOut,
  getUserProfile,
} from "./firebase";
import axios from "axios";
import { db_saveusers } from "@/config_vars";

export interface User {
  user: any;
  profile: any;
  userError: any;
}

interface State {
  user: any | null;
  profile: any;
  error: null;
}

export const useAuthStore = defineStore("authStore", {
  state: (): State => ({
    user: null,
    profile: {
      role: null,
    },
    error: null,
  }),
  getters: {
    isLoggedIn: (state) => state.user !== null,
    userError: (state) => state.error,
  },
  actions: {
    initializeAuthListener() {
      return new Promise((resolve) => {
        fbAuthStateListener(async (user: any) => {
          this.user = user ? user : null;

          if(user){
            const profile = (await getUserProfile()) as any;
            this.profile = profile;
          }

          resolve(true);
        });
      });
    },
    async logInUser(email: string, password: string) {
      try {
        const response = await fbSignIn(email, password);
        this.user = response ? response : null;
        if (this.user) {
          const profile = getUserProfile();

          await profile.then((response) => {
            this.profile = response;
          });
        }
        this.error = null;
        return true;
      } catch (e: any) {
        (this.user = null), (this.error = e.code);
        return false;
      }
    },
    async logoutUser() {

      try {
        await fbSignOut();
        this.user = null;
        this.profile.role = null;
        this.error = null;
        return true;
      } catch (e: any) {
        this.error = e;
        return false;
      }
    },
    async createAccount(email: string, password: string, role: string, name: string) {
      try {
        const { user } = await fbCreateAccount(email, password, name);
        this.user = user ? user : null;
        this.error = null;
        this.profile.role = role;
        const formData = new FormData();
        formData.append("email", email);
        formData.append("uid", <string>user?.$id);
        formData.append("role", role);
        await axios({
          method: "post",
          url: db_saveusers,
          data: formData,
        });
        return true;
      } catch (e: any) {
        this.user = null;
        this.error = e.code;
        return false;
      }
    },
  },
});
